.container-create-user{
    background-color: #f7f5f59e;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 2px 2px 1px -1px #cccccc;
    border:1px solid #f7f5f59e;
    > input, > select{
        background-color: transparent;
        border: none;
        min-height: 30px;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
    }
    > input::placeholder{
        color: rgb(167, 165, 165);
    }
    > input:focus{
        border: none;
        background-color: #f7f5f59e;

    }
   
}

.btn-save{
    background-color: transparent;  
    border-radius: 10px;
    min-width: 180px;
    border: 0.5px solid  rgb(0, 57, 100);;
    padding:5px 25px;
    font-size: large;
    font-weight: 500;
    background-color: rgb(0, 57, 100);
    color: white;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    font-family: "Montserrat",  sans-serif;
    font-weight: 400;
    font-style: normal;
}

.title-header{
    font-family: "Montserrat",  sans-serif;
    color: #4f4f4f;
    font-size: 20px;
    font-weight: 600;
}